import React, { useState, useEffect } from 'react';
import Icon from '../Icon/Icon';
import styles from './IconGrid.module.css';

const IconGrid = ({ iconsArray, onSelection, revealAllIcons, resetGame,shuffledNumbers }) => {
  const [selectedIconIndex, setSelectedIconIndex] = useState(null);

  useEffect(() => {
    if (resetGame) {
      setSelectedIconIndex(null);
    }
  }, [resetGame]);

  const handleClick = (index) => {
    setSelectedIconIndex(index);
    onSelection(iconsArray[index], index, iconsArray);
  };

  return (
    <div className={styles.grid}>
      {iconsArray.map((icon, index) => (
        <Icon 
          key={index}
          src={icon.src}
          label ={icon?.label ? `${icon.label} Gdes` : ""}


          revealed={revealAllIcons}
          isWinner={icon.isWinner}
          isSelected={index === selectedIconIndex}
          onClick={() => !revealAllIcons && handleClick(index)}
          number={shuffledNumbers[index]}
          
        />
      ))}
    </div>
  );
};

export default IconGrid;
