import {
  LOGIN,
  LOGGED_USER,
  REGISTER_CLIENT,
  LOGOUT,
  REFRESH_CREDIT,
  EDIT_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILURE,
  VERIFY_PIN,
  RESET_PASSWORD,
  DISMISS_MODAL,
  NEW_TRANSACTION,
  TOTAL_JOUEUR,
  UPDATE_USER,
  UPDATE_BONUS,
  UPDATE_BALANCE,
  CHANGE_LANGUAGE,
  VERIFY_PIN_FAILURE,
  CAN_WIN,
  GET_TRANSACTIONS,
} from "../actions/types";

export default function user(
  state = {
    id: undefined,
    lastName: "",
    name: "",
    password: "",
    phone: "",
    pin: "",
    status: "",
    role: "",
    transaction: [],
    profile: {
      userId: "",
      balance: 0,
      bonus: 0,
      imageUri: "",
    },
    canWin: false,
  },
  action
) {
  const { type, payload } = action;
  //console.log("enter Payload : ", payload);
  switch (type) {
    case LOGIN:
      return {
        ...state,
        id: payload.user.id,
        lastName: payload.user.lastName,
        name: payload.user.name,
        password: payload.user.password,
        phone: payload.user.phone,
        role: "User",
        status: payload.user.status,
        profile: payload.user.profile,
      };

    case LOGGED_USER:
      return {
        ...state,
        id: payload.user.id,
        lastName: payload.user.lastName,
        name: payload.user.name,
        password: payload.user.password,
        phone: payload.user.phone,
        role: "User",
        status: payload.user.status,
        profile: payload.user.profile,
      };

    case REGISTER_CLIENT:
      return {
        ...state,
        id: payload.user.id,
        lastName: payload.user.lastName,
        name: payload.user.name,
        password: payload.user.password,
        phone: payload.user.phone,
      };

    case LOGOUT:
      return {
        ...state,
        id: undefined,
        lastName: "",
        name: "",
        phone: "",
        pin: "",
        status: "",
        role: "",
        userType: {},
        profile: {},
        canWin: false,
      };

    case REFRESH_CREDIT:
      return {
        ...state,
        credit: payload.data,
      };

    case CAN_WIN:
      return {
        ...state,
        canWin: payload.data,
      };

    case UPDATE_BONUS:
      return {
        ...state,
        profile: {
          ...state.profile,
          bonus: payload.data,
        },
      };

    case UPDATE_BALANCE:
      return {
        ...state,
        profile: {
          ...state.profile,
          balance: payload.data,
        },
      };

    case TOTAL_JOUEUR:
      return {
        ...state,
        totalJoueur: payload.data,
      };

    case NEW_TRANSACTION:
      return {
        ...state,
        credit: state.credit + payload.data.balance,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: payload.data,
      };
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };

    case EDIT_USER:
      return {
        ...state,
        id: payload.result.id,
        balance: payload.result.balance,
        bonus: payload.result.bonus,
        name: payload.result.name,
        phone: payload.result.phone,
        role: "User",
        lastName: payload.result.lastName,
        password: payload.result.password,
      };

    case VERIFY_USER_SUCCESS:
      return {
        ...state,
        phone: payload.data,
        pinModal: true,
        passwordModal: false,
        verifyUserSuccess: true,
      };
    case VERIFY_USER_FAILURE:
      return {
        ...state,
        pinModal: false,
        phone: "",
        passwordModal: false,
        verifyUserSuccess: false,
        existUserPhone: false,
      };

    case VERIFY_PIN:
      return {
        ...state,
        pinModal: false,
        passwordModal: true,
        pin: payload.data,
      };

    case VERIFY_PIN_FAILURE:
      return { ...state, pinModal: false, passwordModal: false };

    case GET_TRANSACTIONS: {
      return {
        ...state,
        transaction: payload.data,
      };
    }

    case RESET_PASSWORD:
      return { ...state, pinModal: false, passwordModal: false };

    case DISMISS_MODAL:
      return { ...state, pinModal: false, passwordModal: false };

    default:
      return state;
  }
}
