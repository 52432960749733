import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Modal, Icon, Button } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";

//Components
import PasswordInput from "../PasswordInput/PasswordInput";

import styles from "./ResetPasswordForm.module.css";

class ResetPasswordForm extends PureComponent {


	render() {
		const { visible, onClose, setPassword, password, handleResetPassword } = this.props;
		const disabled = password === "";
		return (
			<Modal open={visible} onClose={onClose} closeIcon closeOnDimmerClick={false}>
				<Modal.Header>{getLabel("ResetPassword")}</Modal.Header>
				<Modal.Content className={styles.content}>
					<PasswordInput
						icon={"lock"}
						name={"password"}
						value={password}
						placeholder={getLabel("Password")}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</Modal.Content>
				<Modal.Actions>
					<Button fluid onClick={handleResetPassword} disabled={disabled} className={styles.button}>
						<Icon name={"paper plane"} />
						{getLabel("ResetPassword")}
					</Button>
				</Modal.Actions>
			</Modal>
		);
	}
	
}

function mapStateToProps({ user }) {
	return { user };
}

export default connect(mapStateToProps)(ResetPasswordForm);
