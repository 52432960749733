import React, { PureComponent } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Icon, Image } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import { verifyUser, verifyPin, resetPassword, dismissModals } from "../../redux/actions/user";
import { endNavigate, navigate } from "../../redux/actions/navigation";

//Components
import Input from "../../components/Input/Input";
import PinModal from "../../components/PinModal/PinModal";
import ResetPasswordForm from "../../components/ResetPasswordForm/ResetPasswordForm";

import styles from "./ForgotPasswordScreen.module.css";

class ForgotPasswordScreen extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			phone: "",
			pin: "",
			password : "",
		};

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	}

	componentDidUpdate(prevProps) {
		if (this.props.user.verifyUserSuccess !== prevProps.user.verifyUserSuccess) {
		  if (this.props.user.verifyUserSuccess) {
			console.log("this.props.user.verifyUserSuccess :",this.props.user.verifyUserSuccess);
		  } else {
			// The verification failed, handle accordingly
		  }
		}
	}

	handleCancel = e => {
		e.preventDefault();
		this.props.dispatch(navigate("/"));
	};

	handleForgotPassword = e => {
		e.preventDefault();
		this.props.dispatch(verifyUser(this.state.phone));
	};

	handleVerifyPin = pin => {
		this.props.dispatch(verifyPin(this.state.phone, pin));
	};

	handleResetPassword = () => {
		this.props.dispatch(resetPassword(this.props.user.phone,this.props.user.pin,this.state.password));
	};

	handleInputOnChange = e => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	handleDismiss = () => {
		this.props.dispatch(dismissModals());
	};

	
	handleSetPassword = (password) => {
		this.setState({password:password});
	};

	render() {
		const { phone } = this.state;
		const { navigation, location } = this.props;
		const { pinModal, passwordModal } = this.props.user;

		if (navigation.navigate && location.pathname !== navigation.route) {
			return <Redirect push to={navigation.route} />;
		}

		return (
			<div className={styles.container}>
				<PinModal visible={pinModal} onClose={this.handleDismiss} onPress={this.handleVerifyPin} />
				<ResetPasswordForm visible={passwordModal} onClose={this.handleDismiss} onPress={this.handleResetPassword} setPassword = {this.handleSetPassword} password= {this.state.password } handleResetPassword = {this.handleResetPassword}/>
				<div className={styles.logoContainer}>
					<Image src={"/logo3_1.png"} className={styles.logo} />
				</div>
				<div className={styles.inputsContainer}>
					<Input
						icon={"user"}
						name={"phone"}
						value={phone}
						placeholder={getLabel("Phone")}
						onChange={this.handleInputOnChange}
					/>
				</div>
				<div className={styles.buttonsContainer}>
					<Button className={styles.button} onClick={this.handleForgotPassword}>
						<Icon name={"paper plane"} />
						<div className={styles.text}>{getLabel("ResetPassword")}</div>
					</Button>
					<div className={styles.signUpText} onClick={this.handleCancel}>
						<div className={styles.hyperlink}>{getLabel("Cancel")}</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps({ navigation, user }) {
	console.log("user Forget Password: ",user)
	return { navigation, user };
}

export default withRouter(connect(mapStateToProps)(ForgotPasswordScreen));
