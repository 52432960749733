import React, { PureComponent } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Image } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import { register } from "../../redux/actions/user";
import { endNavigate, navigate } from "../../redux/actions/navigation";

//Components
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import Input from "../../components/Input/Input";

import styles from "./SignUpScreen.module.css";

class SignUpScreen extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      lastName: "",
      name: "",
      phone: "509", // Initialiser avec "509"
      password: "",
      pin: "",
      modalVisible: false,
      errorMessage: "",
    };

    if (!this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
  }

  handleCancel = (e) => {
    e.preventDefault();
    this.props.dispatch(navigate("/"));
  };

  handlePrivacy = (e) => {
    e.preventDefault();
    this.setState({ modalVisible: true });
  };

  handleClosePrivacy = (e) => {
    e.preventDefault();
    this.setState({ modalVisible: false });
  };

  handleSignUp = async () => {
    const { lastName, name, phone, password, pin } = this.state;

    // Validate phone length
    if (phone.length !== 11) {
      this.setState({ errorMessage: "Le numéro de téléphone doit avoir 11 caractères." });
      return;
    }

    await this.props.dispatch(register(lastName, name, phone, password, pin));
  };

  handleInputOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === "phone") {
      // Assurer que le préfixe "509" reste en place et que la longueur totale est de 11 caractères
      if (!value.startsWith("509")) {
        return;
      }
      if (value.length <= 11) {
        this.setState({ phone: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  handleCheckOnChange = (e) => {
    e.preventDefault();
    this.setState({ checked: !this.state.checked });
  };

  handleDropdownChange = (e, { value }) => {
    e.preventDefault();
    this.setState({ city: value });
  };

  render() {
    const { lastName, name, phone, password, pin, errorMessage } = this.state;
    const { navigation, location } = this.props;

    if (navigation.navigate && location.pathname !== navigation.route) {
      return <Redirect push to={navigation.route} />;
    }

    const disabled =
      lastName === "" ||
      name === "" ||
      phone === "" ||
      password === "" ||
      pin === "";

    return (
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Image src={"/logo3_1.png"} className={styles.logo} />
        </div>
        <div className={styles.inputsContainer}>
          <Input
            name={"lastName"}
            value={lastName}
            placeholder={getLabel("LastName")}
            type={"text"}
            onChange={this.handleInputOnChange}
          />
          <Input
            name={"name"}
            value={name}
            placeholder={getLabel("FirstName")}
            onChange={this.handleInputOnChange}
          />
          <Input
            name={"phone"}
            type={"text"}
            value={phone}
            placeholder={getLabel("Phone")}
            onChange={this.handleInputOnChange}
          />
          {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
          <PasswordInput
            name={"password"}
            value={password}
            placeholder={getLabel("Password")}
            onChange={this.handleInputOnChange}
          />
          <PasswordInput
            name={"pin"}
            value={pin}
            placeholder={getLabel("RecoveryPIN")}
            onChange={this.handleInputOnChange}
          />
        </div>
        <div className={styles.buttonsContainer}>
          <Button className={styles.button} onClick={this.handleSignUp} disabled={disabled}>
            {getLabel("Register")}
          </Button>
        </div>
        <div className={styles.textCenter}>{getLabel("OR")}</div>
        <div className={styles.buttonsContainer}>
          <Button className={styles.handleCancelButton} onClick={this.handleCancel}>
            {getLabel("Cancel")}
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ navigation }) {
  return { navigation };
}

export default withRouter(connect(mapStateToProps)(SignUpScreen));
