import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Modal, Icon, Button } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";

//Components
import PasswordInput from "../PasswordInput/PasswordInput";

import styles from "./PinModal.module.css";

class PinModal extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			pin: ""
		};
	}

	onInputChange = e => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	handlePin = e => {
		e.preventDefault();
		this.props.onPress(this.state.pin);
		this.setState({ pin: "" });
	};

	render() {
		const { pin } = this.state;
		const { visible, onClose } = this.props;
		const disabled = pin === "";
		return (
			<Modal open={visible} onClose={onClose} closeIcon closeOnDimmerClick={false}>
				<Modal.Header>{getLabel("VerifyPIN")}</Modal.Header>
				<Modal.Content className={styles.content}>
					<PasswordInput
						name={"pin"}
						value={pin}
						placeholder={getLabel("RecoveryPIN")}
						onChange={this.onInputChange}
					/>
				</Modal.Content>
				<Modal.Actions>
					<Button fluid onClick={this.handlePin} disabled={disabled} className={styles.button}>
						<Icon name={"paper plane"} />
						{getLabel("VerifyPIN")}
					</Button>
				</Modal.Actions>
			</Modal>
		);
	}
}

function mapStateToProps({ user }) {
	return { user };
}

export default connect(mapStateToProps)(PinModal);
