import React, { PureComponent } from "react";
import { withRouter, Switch } from "react-router-dom";
import { Dimmer, Modal, Button } from "semantic-ui-react";
import { isIOS } from "react-device-detect";
import PWAPrompt from "react-ios-pwa-prompt";
import { getLabel } from "./constants/Strings";
import Cookie from "react-cookies";

//Redux
import { connect } from "react-redux";
import { logout, rehydrate } from "./redux/actions/user";
import { importSettings } from "./redux/actions/settings";
import { dismissErrors } from "./redux/actions/error";
import { dismissSuccess } from "./redux/actions/success";
//import { newTransaction } from "./redux/actions/transactions";
//Components
import IdleTimer from "react-idle-timer";
import PublicRoute from "./components/PublicRoute/PublicRoute";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Loading from "./components/Loading/Loading";
import ErrorDialog from "./components/ErrorDialog/ErrorDialog";
import SuccessDialog from "./components/SuccessDialog/SuccessDialog";
//Screens
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen/ForgotPasswordScreen";
import SignUpScreen from "./screens/SignUpScreen/SignUpScreen";
import HomeScreen from "./screens/HomeScreen/HomeScreen";
import GameScreen from "./screens/GameScreen/GameScreen";
import SettingsScreen from "./screens/SettingsScreen/SettingsScreen";
import OperationScreen from "./screens/OperationScreen/OperationScreen";
import RulesScreen from "./screens/RulesScreen/RulesScreen";
import TransactionScreen from "./screens/TransactionScreen/TransactionScreen";
import WithDrawScreen from "./screens/WithDrawScreen/WithDrawScreen";

class App extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      installModalIOS: false,
      installModalAndroid: false,
    };

    this.props.dispatch(importSettings());
    this.props.dispatch(rehydrate());
    window.scrollTo(0, 0);
    this.installPrompt = null;
  }

  componentDidMount = async () => {
    if (
      isIOS &&
      (!window.matchMedia("(display-mode: standalone)").matches ||
        !window.navigator.standalone)
    ) {
      //console.log("Opened on IOS");
      this.setState({ installModalIOS: true });
    } else {
      var cookie = await Cookie.load("visited");
      if (cookie === undefined) {
        //console.log("Opened on Android or Desktop");
        Cookie.save("visited", { visited: true }, { path: "/", maxAge: 3600 });
        window.addEventListener("beforeinstallprompt", (e) => {
          // See if the app is already installed, in that case, do nothing
          if (
            (window.matchMedia &&
              window.matchMedia("(display-mode: standalone)").matches) ||
            window.navigator.standalone === true
          ) {
            return false;
          }
          this.installPrompt = e;
          this.setState({ installModalAndroid: true });
        });
      }
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.user.id && !this.props.user.id) {
      this.props.history.push("/"); // Redirect to login
    }
  };

  onIdle = () => {
    if (this.props.isLoggedIn) {
      return this.props.dispatch(logout());
    }
  };

  closeIOSModal = () => {
    this.setState({ installModalIOS: false });
  };

  closeAndroidModal = () => {
    this.setState({ installModalAndroid: false });
  };

  handleInstallApp = async () => {
    this.installPrompt.prompt();
    const { outcome } = await this.installPrompt.userChoice;
    if (outcome === "accepted") {
      //console.log("Installed");
      this.setState({ installModalAndroid: false });
    } else {
      //console.log("Not Installed");
      this.setState({ installModalAndroid: false });
    }
  };

  handleDismissErrors = (e) => {
    e.preventDefault();
    this.props.dispatch(dismissErrors());
  };

  handleDismissSuccess = (e) => {
    e.preventDefault();
    this.props.dispatch(dismissSuccess());
  };

  render() {
    const { loading, isLoggedIn, error, success, user } = this.props;
    const { installModalAndroid, installModalIOS } = this.state;

    if (user.id !== null) {
      return (
        <Dimmer.Dimmable
          blurring
          dimmed={loading.isFetching || error.error || success.success}
        >
          <Modal open={installModalAndroid} onClose={this.closeAndroidModal}>
            <Modal.Content>
              <h3>{getLabel("PromptTitle")}</h3>
              <p>{getLabel("PromptBody")}</p>
            </Modal.Content>
            <Modal.Actions>
              <Button fluid onClick={this.handleInstallApp} color={"green"}>
                {getLabel("Install")}
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal open={installModalIOS} onClose={this.closeIOSModal}>
            <PWAPrompt
              timesToShow={5}
              delay={0}
              permanentlyHideOnDismiss={false}
              copyTitle={getLabel("PromptTitle")}
              copyBody={getLabel("PromptBody")}
              copyShareButtonLabel={getLabel("Prompt1")}
              copyAddHomeButtonLabel={getLabel("Prompt2")}
              copyClosePrompt={getLabel("Cancel")}
            />
          </Modal>
          <Loading isFetching={loading.isFetching} />
          <ErrorDialog
            error={error.error}
            title={error.errorTitle}
            text={error.errorText}
            onPress={this.handleDismissErrors}
          />
          <SuccessDialog
            success={success.success}
            title={success.successTitle}
            text={success.successText}
            onPress={this.handleDismissSuccess}
          />
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            element={document}
            onIdle={this.onIdle}
            timeout={1000 * 60 * 15}
          />
          <Switch>
            <PublicRoute
              isLoggedIn={isLoggedIn}
              role={user.role}
              exact
              path={"/"}
              component={LoginScreen}
            />

            <PublicRoute
              isLoggedIn={isLoggedIn}
              role={user.role}
              exact
              path={"/sign-up"}
              component={SignUpScreen}
            />

            <PublicRoute
              isLoggedIn={isLoggedIn}
              role={user.role}
              exact
              path={"/forgot-password"}
              component={ForgotPasswordScreen}
            />

            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={user.role}
              exact
              path={"/home-screen"}
              component={HomeScreen}
            />

            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={user.role}
              path={"/play-game"}
              component={GameScreen}
            />

            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={user.role}
              path={"/settings"}
              component={SettingsScreen}
            />
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={user.role}
              exact
              path={"/operation"}
              component={OperationScreen}
            />
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={user.role}
              exact
              path={"/withdraw"}
              component={WithDrawScreen}
            />
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={user.role}
              exact
              path={"/rules-screen"}
              component={RulesScreen}
            />
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={user.role}
              exact
              path={"/transaction"}
              component={TransactionScreen}
            />
          </Switch>
        </Dimmer.Dimmable>
      );
    } else {
      return (
        <PublicRoute
          isLoggedIn={isLoggedIn}
          role={user.role}
          exact
          path={"/"}
          component={LoginScreen}
        />
        // <Dimmer.Dimmable blurring dimmed={true}>
        // 	<Loading isFetching={true} />
        // </Dimmer.Dimmable>
      );
    }
  }
}

function mapStateToProps({ loading, user, error, success }) {
  const isLoggedIn = user.id !== null && user.id !== undefined;
  return { loading, error, success, user, isLoggedIn: isLoggedIn };
}

export default withRouter(connect(mapStateToProps)(App));
