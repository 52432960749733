// Existing imports
import React from 'react';
import styles from './RulesModal.module.css';
import { getLabel } from '../../constants/Strings';

const RulesModal = ({ isOpen, onClose }) => {
  // Existing useEffect

  return (
    <div className={isOpen ? styles.modalOpen : styles.modalClosed} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <div className={styles.scrollText}>
          {getLabel("A1")}
        </div>
      </div>
    </div>
  );
};

export default RulesModal;
