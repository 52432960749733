import React, { PureComponent } from "react";
import styles from "./PasswordInput.module.css";

class PasswordInput extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			type: "password"
		};
	}

	handleShowHide = event => {
		event.preventDefault();
		event.stopPropagation();
		this.setState({
			type: this.state.type === "input" ? "password" : "input"
		});
		this.focus();
	};

	handleRef = c => {
		this.inputRef = c;
	};

	focus = () => {
		this.inputRef.focus();
	};

	render() {
		const { name, value, placeholder, onChange, disabled } = this.props;
		

		return (
			<div className={styles.inputContainer}>
				<input
					disabled={disabled}
					className={styles.input}
					ref={this.handleRef}
					type={this.state.type}
					placeholder={placeholder}
					name={name}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}
}

export default PasswordInput;
