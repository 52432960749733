import store from "../redux/store";

const strings = {
	values: {
		MyBoulpik: {
			english: "My Boulcash",
			french: "Mes Boulcash",
			creole: "Boulcash Mwen",
		},
		Operation: {
			english: "Operation ",
			french: "Operation ",
			creole: "Operasyon ",
		},
		WinningHeaderText: {
			english: "Congratulations!",
			french: "Félicitations!",
			creole: "Bravo!",
		},

		WinningBodyText: {
			english: "You have won ",
			french: "Vous avez Gagné  ",
			creole: "ou genyen ",
		},
		HomeScreen: {
			english: "Prizes",
			french: "Primes ",
			creole: "Prim ",
		},
		History: {
			english: "History",
			french: "Historique",
			creole: "Istorik",
		},
		Cart: {
			english: "Cart",
			french: "Chariot",
			creole: "Charyo",
		},
		NextDraws: {
			english: "Next Draws",
			french: "Prochain Tirage",
			creole: "Lòt Tiraj",
		},
		Draw: {
			english: "Draw",
			french: "Tirage",
			creole: "Tiraj",
		},
		Next: {
			english: "Next",
			french: "Prochain",
			creole: "Lòt",
		},
		AuthorizedDealers: {
			english: "Authorized Dealers",
			french: "Distributeurs Autorisés",
			creole: "Distribitè Otorize",
		},
		SellersList: {
			english: "Sellers List",
			french: "Liste des Vendeurs",
			creole: "Lis Vandè",
		},
		WithdrawalCredit: {
			english: "Withdrawal Credit",
			french: "Retrait Credit",
			creole: "Retrè Kredi",
		},
		Finance: {
			english: "Finance",
			french: "Finance",
			creole: "Finans",
		},
		Settings: {
			english: "Settings",
			french: "Configurations",
			creole: "Konfigirasyon",
		},
		Print: {
			english: "Print PDF ",
			french: "Imprimer PDF ",
			creole: "Enprime PDF ",
		},
		Help: {
			english: "Help",
			french: "Aide",
			creole: "Èd",
		},
		Balance: {
			english: "Balance",
			french: "Balance",
			creole: "Balans",
		},
		TransferCredit: {
			english: "Transfer Credit",
			french: "Transferér Recharge",
			creole: "Tansfere Rechaj",
		},
		CreateAdmin: {
			english: "Create Admin",
			french: "Créer Administrateur ",
			creole: "Kreye Administratè",
		},
		DrawDetails: {
			english: "Draw Details",
			french: "Details des tirages  ",
			creole: "Detay tiraj yo",
		},
		
		CreateInvestor: {
			english: "Create Investor",
			french: "Créer Investisseur ",
			creole: "Kreye Envestisè",
		},

		SeeInvestor: {
			english: "See Investor",
			french: "Voir Investisseur ",
			creole: "gade Envestisè",
		},
		
		Investor: {
			english: "Investors",
			french: "Investisseurs ",
			creole: "Envestisè yo",
		},
			
		ChoiceLanguage: {
			english: "Select a language",
			french: "Veuillez choisir une langue ",
			creole: "Chwazi yon lang",
		},

		CreateSeller: {
			english: "Create Seller",
			french: "Créer Vendeur",
			creole: "Kreye Vandè",
		},
		BoulcashOne: {
			english: "Boulcash Winner 1er Place",
			french: "Boulcash Gagnant 1er Place",
			creole: "Boulcash Gayan 1e Place",
		},
		BoulcashTwo: {
			english: "Boulcash Winner 2e Place",
			french: "Boulcash Gagnant 2e Place",
			creole: "Boulcash Gayan 2e Place",
		},
		BoulcashThree: {
			english: "Boulcash Winner 3e Place",
			french: "Boulcash Gagnant 3e Place",
			creole: "Boulcash Gayan 3e Place",
		},
		BoulcashFour: {
			english: "Boulcash Winner 4e Place",
			french: "Boulcash Gagnant 4e Place",
			creole: "Boulcash Gayan 4e Place",
		},
		BoulcashFive: {
			english: "Boulcash Winner 5e Place",
			french: "Boulcash Gagnant 5e Place",
			creole: "Boulcash Gayan 5e Place",
		},
		SetWinners: {
			english: "SetWinners",
			french: "Ajouter Gagnants",
			creole: "Mete Gayan yo",
		},
		CreateDealer: {
			english: "Create Dealer",
			french: "Créer Distributeurs",
			creole: "Kreye Distribitè",
		},
		CreateDraw: {
			english: "Create Draw",
			french: "Créer Tirage",
			creole: "Kreye Tiraj",
		},
		UpdateDraw: {
			english: "Update Draw",
			french: "Modifier Tirage",
			creole: "Modifye Tiraj",
		},
		WinnerDetails: {
			english: "Winner Details",
			french: "Detail Gagnant",
			creole: "Detay Moun Ki genyen",
		},
		Transaction: {
			english: "Transaction",
			french: "Transaction",
			creole: "Transaksyon",
		},
		Share: {
			english: "Share",
			french: "Repartition",
			creole: "Repatisyon",
		},
		Benefit: {
			english: "Benef. of ass.",
			french: "Benef. Des ass.",
			creole: "Benef. asosye yo",
		},
		PercentEntr: {
			english: "Operating budget.",
			french: "Budget de Fonct.",
			creole: "Bidjè fonksyònman",
		},
		PercentDev: {
			english: "Operating Dev.",
			french: "Budget de Dev.",
			creole: "Bidjè devlopman",
		},
		AccountOff: {
			english: "Account Off",
			french: "Compte Off",
			creole: "Kont femen",
		},
		Unexpected: {
			english: "Unexpected",
			french: "Imprevu",
			creole: "Inprevi",
		},
		Earnings: {
			english: "My Earnings",
			french: "Mes Gains",
			creole: "Kob Pam",
		},
		EarningsOf: {
			english: "Earnings of Draw",
			french: "Gains du Tirage",
			creole: "Benefis ou nan Tiraj la",
		},
		Sales: {
			english: "Sales",
			french: "Ventes",
			creole: "Lavant",
		},
		AutoBoulpik: {
			english: "Auto Boulcash",
			french: "Auto Boulcash",
			creole: "Boulcash otomatik",
		},
		Home: {
			english: "Home",
			french: "Accueil",
			creole: "Akèy",
		},
		ChooseDraw: {
			english: "Choose Draw",
			french: "Choisissez Tirage",
			creole: "Chwazi Tiraj",
		},
	
		Loading: {
			english: "Loading",
			french: "Chargement",
			creole: "Chajman",
		},
		Price: {
			english: "Price",
			french: "Prix",
			creole: "Pri",
		},
		BuyNow: {
			english: "Buy Now",
			french: "Acheter",
			creole: "Achte Kounya",
		},
		Buy: {
			english: "Buy",
			french: "Acheter",
			creole: "Achte",
		},
		Remove: {
			english: "Remove",
			french: "Eliminer",
			creole: "Elimine",
		},
		Dismiss: {
			english: "Dismiss",
			french: "Rejeter",
			creole: "Rejte",
		},
		SignOut: {
			english: "Sign Out",
			french: "Sortir",
			creole: "Kite",
		},
		Ok: {
			english: "Yes",
			french: "Oui",
			creole: "Wi",
		},
		SignOutMessage: {
			english: "Are you sure you want to sign out?",
			french: "Etes vous sûre de vouloir sortir ?",
			creole: "Ou sur ke ou vle kite ?",
		},
		
		MailPdf: {
			english: "Send",
			french: "Envoyer",
			creole: "Ok",
		},
		StartIndex: {
			english: "From",
			french: "De",
			creole: "A pati",
		},
		EndIndex: {
			english: "To",
			french: "Jusqu'a",
			creole: "Jiska",
		},
		PrintHeader: {
			english: "PRINT BOULPIK?",
			french: "PRINTER BOULPIK ?",
			creole: "PRINT BOULPIK ou a ?",
		},
		SignIn: {
			english: "Sign In",
			french: "Se Connecter",
			creole: "Antre",
		},
		Register: {
			english: "Register",
			french: "Enregistrer",
			creole: "Anrejistre",
		},

		
		DontHaveAnAccount: {
			english: "New ?",
			french: "Nouveau?",
			creole: "Nouvo ?",
		},
		SignUp: {
			english: "Create Your Account !",
			french: "Créer Votre Compte !",
			creole: "Kreye Kont Ou !",
		},

		Winners_Draw: {
			english: "5 winners/ draw",
			french: "5 gagnants/ tirage",
			creole: "5 gayan/ tiraj",
		},
		
		AccountType: {
			english: "Account Type",
			french: "Type de Compte",
			creole: "Kalite Kont",
		},
		Done: {
			english: "Done",
			french: "Terminé",
			creole: "Fini",
		},
		EditAccount: {
			english: "Edit Account",
			french: "Modifier le Compte",
			creole: "Modifye Kont",
		},
		FullName: {
			english: "Full Name",
			french: "Nom Complet",
			creole: "Non Konplè",
		},
		FirstName: {
			english: "FirstName",
			french: "Prenom",
			creole: "prenon",
		},
		LastName: {
			english: "LastName",
			french: "Nom",
			creole: "Non",
		},

		Play: {
			english: "Play",
			french: "Jouer",
			creole: "Jwe",
		},
		
		Phone: {
			english: "Phone",
			french: "Téléphone",
			creole: "Telefòn",
		},
		Email: {
			english: "Email",
			french: "Courriel",
			creole: "Imèl",
		},
		City: {
			english: "State",
			french: "Departement",
			creole: "Depatman",
		},
		Sector: {
			english: "City",
			french: "Ville",
			creole: "Vil",
		},
		RecoveryPIN: {
			english: "Recovery PIN",
			french: "Ajouter un PIN",
			creole: "Mete yon Pin",
		},
		VerifyPIN: {
			english: "Verify PIN",
			french: "Vérifier le PIN",
			creole: "Verifye yon Pin",
		},
		Percent: {
			english: "percent",
			french: "pourcentage",
			creole: "santay",
		},
		Address: {
			english: "Address",
			french: "Addresse",
			creole: "Adrès",
		},
		AddToCart: {
			english: "Add To Cart",
			french: "Ajouter au Chariot",
			creole: "Mete Nan Charyo",
		},
		Matricule: {
			english: "Registration Number",
			french: "Matricule",
			creole: "Matrikil",
		},
		ForgotYourPassword: {
			english: "Forgot Your Password?",
			french: "Mot de passe oublié ?",
			creole: "Bliye modpas?",
		},
		Password: {
			english: "Password",
			french: "Mot de Passe",
			creole: "Modpas",
		},
		ConfirmPassword: {
			english: "Re-enter Password",
			french: "Confirmer mot de Passe",
			creole: "Konfime Modpas",
		},
		NewPassword: {
			english: "New Password",
			french: "Nouveau Mot de Passe",
			creole: "Nouvo Modpas",
		},
		OldPassword: {
			english: "Old Password",
			french: "Ancien Mot de Passe",
			creole: "Ansyen Modpas",
		},
		Save: {
			english: "Save",
			french: "Sauvegarder",
			creole: "Konsève",
		},
		Profile: {
			english: "Profile",
			french: "Profil",
			creole: "Profil",
		},
		Logout: {
			english: "Logout",
			french: "Sortie",
			creole: "Soti",
		},
		
		Rules: {
			english: "Rules",
			french: "Règles",
			creole: "Règ",
		},

		Hello: {
			english: "Hello",
			french: "Bonjour",
			creole: "Bonjou",
		},
		
		
		Return: {
			english: "Return",
			french: "Retour",
			creole: "Tounen",
		},
		
		InitialSettings: {
			english: "Initial Settings",
			french: "Première Configuration",
			creole: "Premye Konfigirasyon",
		},
		Language: {
			english: "Language",
			french: "Langue",
			creole: "Lang",
		},
		KeepSession: {
			english: "Keep Session",
			french: "Garder la Session",
			creole: "Kenbe Sesyon",
		},
		New: {
			english: "New",
			french: "Nouveau",
			creole: "Nouvo",
		},
		OR: {
			english: "OR",
			french: "OU",
			creole: "OSWA",
		},
		Items: {
			english: "Items",
			french: "Elements",
			creole: "Eleman",
		},

		DetailsBoulcash: {
			english: "Boulcash Details",
			french: "Detailles Boulcash",
			creole: "Enfo Boulcash",
		},

		
		Total: {
			english: "Total",
			french: "Total",
			creole: "Total",
		},
		BoulpikPlayed: {
			english: "Boulcash Played",
			french: "Boulcash Joués",
			creole: "Boulcash Jwe",
		},
		Participant: {
			english: "Participant",
			french: "Participant",
			creole: "Patisipan",
		},
		Add: {
			english: "Add",
			french: "Ajouter",
			creole: "Ajoute",
		},
		BuyAll: {
			english: "Buy All",
			french: "Tout Acheter",
			creole: "Achte Tout",
		},
		PrizeDraw: {
			english: "Prizes of Draw",
			french: "Prime du Tirage",
			creole: "Prim Tiraj",
		},
		YourBalance: {
			english: "Your Balance",
			french: "Votre Balance",
			creole: "Balans ou ",
		},
		CreditToTransfer: {
			english: "Credits to Transfer",
			french: "Crédits à Transférer",
			creole: "Kredi pou Transfere",
		},
		CreditToRechage: {
			english: "Credits to Recharge",
			french: "Crédits à Recharger",
			creole: "Kredi pou Rechaje",
		},
		Date: {
			english: "Date",
			french: "Date",
			creole: "Dat",
		},
		Type: {
			english: "Type",
			french: "Type",
			creole: "Kalite",
		},
		Recipient: {
			english: "Sender/Receiver",
			french: "Émetteur/Récepteur",
			creole: "Emetè/Reseptè",
		},
		ResetPassword: {
			english: "Reset Password",
			french: "Changer Mot de Passe",
			creole: "Chanje Modpas",
		},
		Cancel: {
			english: "Cancel",
			french: "Quitter",
			creole: "Kite",
		},
		
		Replay: {
			english: "Replay",
			french: "Rejouer",
			creole: "Rejwe",
		},
		ConfirmSignUp: {
			english: "I am over 18 years old and I agree with the",
			french: "J'ai plus de 18 ans et j'accepte les",
			creole: "Mwen gen plis pase 18 lane epi mwen dakò avèk",
		},
		PrivacyPolicy: {
			english: "Terms of Use & Privacy Policy",
			french: "Conditions d'utilisation et la politique de confidentialité",
			creole: "Regleman pou Itilize ak Règleman sou enfòmasyon prive",
		},
		PriceOf: {
			english: "Price of Tize Milyon",
			french: "Prix de Tize Milyon",
			creole: "Pri nan Tize Milyon",
		},
		Initial: {
			english: "Initial Prize",
			french: "Primes Initiales",
			creole: "Prim Inisyal",
		},
		RememberMe: {
			english: "Remember Me",
			french: "Rappler votre Email ou Telephone",
			creole: "Raplew Imel o Telefòn",
		},
		_1stPlace: {
			english: "1st Place",
			french: "1ère Place",
			creole: "1è Plas",
		},
		_2ndPlace: {
			english: "2nd Place",
			french: "2ème Place",
			creole: "2è Plas",
		},
		_3rdPlace: {
			english: "3rd Place",
			french: "3ème Place",
			creole: "3è Plas",
		},
		_4thPlace: {
			english: "4th Place",
			french: "4ème Place",
			creole: "4è Plas",
		},
		_5thPlace: {
			english: "5th Place",
			french: "5ème Place",
			creole: "5è Plas",
		},
		Days: {
			english: "Days",
			french: "Jours",
			creole: "Jou",
		},
		Hours: {
			english: "Hours",
			french: "Heures",
			creole: "Èdtan",
		},
		Minutes: {
			english: "Minutes",
			french: "Minutes",
			creole: "Minit",
		},
		Seconds: {
			english: "Seconds",
			french: "Secondes",
			creole: "Segond",
		},
		Hour: {
			english: "Hour",
			french: "Heure",
			creole: "Èdtan",
		},
		Player: {
			english: "Player",
			french: "Joueur",
			creole: "Jwè",
		},
		Amount: {
			english: "Amount",
			french: "Montant",
			creole: "Montan",
		},
		Dealer: {
			english: "Dealer",
			french: "Distributeur",
			creole: "Distribitè",
		},
		Seller: {
			english: "Seller",
			french: "Vendeur",
			creole: "Vandè",
		},
		Admin: {
			english: "Admin",
			french: "Administrateur",
			creole: "Administratè",
		},
		Super: {
			english: "Super Admin",
			french: "Meilleur Administrateur",
			creole: "Mèyè Administratè",
		},
		BoulpiksPlayed: {
			english: "Tize Cash Played",
			french: "Tize Cash Jouée",
			creole: "Tize Cash Jwe",
		},
		placeHolderCityState: {
			english: "City or State",
			french: "Ville ou Departement",
			creole: "Vil oubyen Depatman",
		},
		System: {
			english: "System",
			french: "Système",
			creole: "Sistèm",
		},
		Delete: {
			english: "Delete Tize Cash",
			french: "Eliminer Tize Cash",
			creole: "Efase Tize Cash",
		},
		AreYouSure: {
			english: "Are you sure you want to delete the Tize Cash?",
			french: "Êtes-vous sûre de vouloir eliminer le Tize Cash?",
			creole: "Èske ou sèten ke ou vle efase Tize Cash la?",
		},
		ConfirmTransfer: {
			english: "Are you sure you want to transfer the credit?",
			french: "Êtes-vous sûre de vouloir transférer le crédits?",
			creole: "Èske ou sèten ke ou vle transfere kredi la?",
		},
		DrawDates: {
			english: "Draw Dates",
			french: "Tirage Dates",
			creole: "Tiraj Dat",
		},
		Prix: {
			english: "Price",
			french: "Prix",
			creole: "Pri ",
		},

		Tirage: {
			english: "Draw",
			french: "Tirage",
			creole: "Tiraj ",
		},

		Dates: {
			english: "Date",
			french: "Date",
			creole: "dat ",
		},

		Frequency: {
			english: "Frequency",
			french: "Frequence",
			creole: "Frekans",
		},


		Nickname: {
			english: "Nickname",
			french: "Surnom",
			creole: "Nonjwet",
		},
		Accept: {
			english: "Accept",
			french: "Acceptez",
			creole: "Aksepte",
		},
		Decline: {
			english: "Decline",
			french: "Refusez",
			creole: "refize",
		},
		Install: {
			english: "Install",
			french: "Installer",
			creole: "Enstale",
		},
		PromptTitle: {
			english: "Add to Home Screen",
			french: "Ajouter sur l’ecran d’acceuil",
			creole: "Ajoutel nan ekran prensipal",
		},
		PromptBody: {
			english:
				"This website has app functionality. Add it to your home screen to use it in fullscreen and while offline.",
			french:
				"Ce Site Web a la fonctionnalité d’une application..Ajouter le dans votre ecran d acceuil pour l’utiliser hors-ligne et en plein d ecran .",
			creole:
				"Sit wèb sa gen fonksyonalite aplikasyon telefon, ajoute nan ekran prensipal ou, wap k itilizel San koneksyon entenet.",
		},
		HomeText: {
			english: "Find the logo to win",
			french: "Trouvez le logo pour gagner",
			creole: "Jwenn logo la pou genyen",
		},
		PrizeMoney: {
			english: "100.000 gdes",
			french: "100.000 gdes",
			creole: "100.000 gdes",
		},
		LowBalance: {
			english: "Low balance",
			french: "Solde faible",
			creole: "Balans ba",
		},
		LowBalanceText: {
			english: "You need at least 5 gourdes to play. Please top up your balance.",
			french: "Vous avez besoin d'au moins 5 gourdes pour jouer. Veuillez recharger votre solde.",
			creole: "Ou bezwen omwen 5 goud dlo pou jwe. Tanpri monte balans ou an.",
		},

		RulesFee: {
			english: "Notice",
			french: " Important",
			creole: " enpotan",
		},
		Withdraw: {
			english: "Withdraw",
			french: " Retrait",
			creole: " Retire",
		},
		
		RulesFeeText: {
			english: "Your account will be reduced by ",
			french: " Votre compte sera débité de ",
			creole: "Kont ou apdiminye de ",
		},
		
		Close: {
			english: "Close",
			french: "Fermer",
			creole: "Fèmen",
		},
		RechargeCredit: {
			english: "Recharge ",
			french: "Recharger",
			creole: "Rechaje",
		},
			
			
		UpdateBalance: {
			english: "Your balance has been successfully updated.",
			french: "Votre solde a été mis à jour avec succès.",
			creole: "Balans ou a ajou avèk siksè.",
		},
		
		



		
		
		
		

		Prompt1: {
			english: "1) Press the 'Share' button.",
			french: "1) Appuiyer sur le boutton 'Partager'.",
			creole: "1) Peze sou bouton 'Pataje'.",
		},
		Prompt2: {
			english: "2) Press 'Add to Home Screen'.",
			french: "2) Appuyer  sur 'Ajouter sur l’ecran d’acceuil'.",
			creole: "2) Peze sou 'Ajoutel nan ekran prensipal'.",
		},
		RechargeWith: {
			english: "Recharge With",
			french: "Recharge Avec",
			creole: "Rechaje Avèk",
		},
		PlayBoulpik: {
			english: "Recharge With",
			french: "Recharge Avec",
			creole: "Rechaje Avèk",
		},
		ContactUs: {
			english: "Contact Us",
			french: "Contactez-Nous",
			creole: "Kontakte Nou",
		},
		FAQ: {
			english: "Frquently Asked Questions",
			french: "Foire Aux Questions",
			creole: "Kesyon Ak Repons",
		},
		Q1: {
			english: "What is Tize Cash ?",
			french: "Qu'est-ce que Tize Cash ?",
			creole: "Kisa Tize Cash  ye?",
		},
		Q2: {
			english: "What is a  BoulCash?",
			french: "Qu'est-ce que BoulCash?",
			creole: "Kisa BoulCash ye?",
		},
		Q3: {
			english: "How to participate in the Tize Cash  Draw?",
			french: "Comment participer au Tirage de Tize Cash ?",
			creole: "Kijan poum patisipe nan tiraj Tize Cash ?",
		},
		Q4: {
			english: "How to recharge my account?",
			french: "Comment récharger mon compte?",
			creole: "Kijan poum Rechaje Kont mw?",
		},
		Q5: {
			english: "How do I top up someone else's account?",
			french: "Comment recharger le compte de quelqu'un d'autre?",
			creole: "Kijan poum rechaje kont pou yon lot moun?",
		},
		Q6: {
			english: "How to check the Balance of my account?",
			french: "Comment vérifier la Balance de mon Compte?",
			creole: "Kijan poum verifye balans mw?",
		},
		Q7: {
			english: "What are the Prizes for the winners?",
			french: "Quels sont les primes pour les gagnants?",
			creole: "Ki Prim ki genyen pou gayan yo?",
		},
		A1: {
			english: "It's a game that you choose an icon, if you find the logo you win, if you find bonus you'll earn the bonus, else try again",
			french: "C'est un jeu où tu choisis une icône, si tu trouves le logo tu gagnes, si tu trouves un bonus tu gagneras le bonus, sinon réessaye",
			creole: "Se yon jwèt ke ou chwazi yon icon, si ou jwenn logo ou genyen an, si ou jwenn bonis ou pral touche bonis la, sinon eseye ankò",
		},
		A2: {
			english: "Boulcash is a 5-digit number.",
			french: "BoulCash est un nombre à 5 chiffres.",
			creole: "Tize Cash se yon nomb ki konpoze de 5 chif.",
		},
		A3: {
			english:
				"1.- Go to the menu.\n2.- My Boulcash.\n3.- Buy Boulcash.\n4.- Select the draw you want to play.\n5.- Enter the 5-digit number and click Buy Now.",
			french:
				"1.- Aller au menu.\n2.- Boulcash.\n3.- Achetez Boulcash.\n4.- Sélectionnez le tirage auquel vous voulez jouer.\n5.- Entrez le numéro à 5 chiffres et cliquez sur Acheter maintenant.",
			creole:
				"1.- Ale nan menu.\n2.- Boulcash mwen.\n3.- Achte Boulcash.\n4.- Chwazi tiraj kew vle jwe a.\n5.- Mete nomb 5 chif la, epi klike sou Achte Kounya.",
		},
		A4: {
			english: "With moncash or with sellers.",
			french: "Avec moncash ou avec des vendeurs.",
			creole: "Avek moncash oubyen avek vandè.",
		},
		A5: {
			english:
				"1.- Go to the menu.\n2.- Transfer Credits.\n3.- Enter the number of the person sending the recharge and the balance.\n4.- Transfer.",
			french:
				"1.- Aller au menu.\n2.- Transférer Recharge.\n3.- Entrez le numéro de la personne qui envoie la recharge et le solde.\n4.- Transfert.",
			creole:
				"1.- Ale nan menu.\n2.- Tranfere Rechaj.\n3.- Mete numero moun wap voye rechaj la ak balans la.\n4.- Transfere.",
		},
		A6: {
			english: "Open the menu, your balance is below the Tize cash logo.",
			french: "Aller au menu, votre solde est en dessous du logo Tize cash.",
			creole: "Ale nan menu, balans ou an anba logo Tize cash la.",
		},
		A7: {
			english: "1.- Go to the menu.\n2.- Click Weekly Prizes, and see the amount of money for each seat.",
			french: "1.- Aller au menu.\n2.- Cliquez Primes, et voir le montant d'argent pour chaque siège.",
			creole: "1.- Ale nan menu.\n2.- Klike sou prim, epi wa wè kantite kòb pou chak plas yo.",
		},
	},
};

function getLabel(code) {
	const word = strings.values[code];
	const state = store.getState();
	const language = state.settings.language;
	return word ? word[language] : "";
}

export { getLabel };
