import React from 'react';
import styles from './Icon.module.css';

const Icon = ({ src, label, revealed, onClick, isWinner, isSelected, number,bonus }) => {
  // let iconClass = '';
  // if(revealed  && isWinner && bonus){
  //   iconClass = `${styles.winning}`;
  // } else if (revealed && isWinner && !bonus){
  //   iconClass = `${styles.winningLogo}`;
  // }else {
  //   iconClass = `${styles.icons}`;
  // }
  const iconClass = `${styles.icons} ${(isWinner || bonus) && revealed ? styles.winning : ''}${bonus ? styles.bonus : ''}`;

  const wrapperClass = `${styles.iconWrapper} ${isSelected && revealed ? styles.selected : ''} ${revealed ? styles.disabled : ''}`;


 

 
  return (
    <div className={wrapperClass} onClick={onClick}>
      {revealed ? (
        <img src={src} alt="Icon" className={iconClass} />
      ) : (
        <div className={iconClass}>{number}</div> 
      )}
      {revealed && label && (
        <div className={styles.iconLabel}>{label}</div>
      )}
    </div>
  );
};

export default Icon;
