import React from "react";
import styles from "./RulesScreen.module.css";
import Menu from "../../components/Menu/Menu";

const RulesScreen = () => {
  return (
    <div className={styles.tutorielScreen}>
      <Menu />
      <div className={styles.header}>
        <h1>Tutoriel - Tizè Milyon</h1>
        <p>Learn how to play Tizè Milyon and win big!</p>
      </div>

      <div className={styles.gameOverview}>
        <h2>Qu'est-ce que Tizè Milyon ?</h2>
        <p>
          Tizè Milyon est une plateforme de jeu de hasard offrant la possibilité
          de gagner jusqu'à un million de gourdes.
        </p>
      </div>

      <div className={styles.howToPlay}>
        <h2>Comment jouer à ce jeu ?</h2>
        <p>Dans la page principale, cliquez sur jouer pour accéder au jeu.</p>
      </div>

      <div className={styles.winningCriteria}>
        <h2>Quand est-ce qu'un joueur gagne à Tizè Milyon ?</h2>
        <p>
          En cliquant sur jouer, trouvez le logo Tizè Milyon ou l'un des
          multiples bonus pour gagner.
        </p>
      </div>

      <div className={styles.prizes}>
        <h2>Qu'est-ce qu'on gagne ?</h2>
        <p>
          Le logo Tizè Milyon permet de gagner la somme disponible. Les bonus
          peuvent être inférieurs ou supérieurs à 1,000 gdes.
        </p>
      </div>

      <div className={styles.accountManagement}>
        <h2>Comment gérer son compte ?</h2>
        <p>
          Utilisez Mon Cash pour recharger le compte et retirer les gains chez
          un agent autorisé.
        </p>
      </div>

      <div className={styles.footer}>
        <p>
          Explorez les ressources supplémentaires, le support client, et les
          conditions générales.
        </p>
      </div>
    </div>
  );
};

export default RulesScreen;
