import React from 'react';
import { List, Icon, Flag } from 'semantic-ui-react';
import styles from './LanguagePicker.module.css'; // Update the import path according to your file structure

const LanguagePicker = ({ selectedLanguage, onLanguageSelect, languages }) => (
  <List selection verticalAlign="middle" className={styles.languageList}>
    {languages.map(lang => (
      <List.Item
        key={lang.value}
        active={selectedLanguage === lang.value}
        onClick={() => onLanguageSelect(lang.value)}
        className={styles.languageItem}
      >
        <Flag name={lang.flag} />
        <List.Content>
          <List.Header>{lang.text}</List.Header>
        </List.Content>
        {selectedLanguage === lang.value && (
          <Icon name="check" className={styles.checkIcon} />
        )}
      </List.Item>
    ))}
  </List>
);

export default LanguagePicker;
