import React from 'react';
import styles from './UserProfile.module.css'; // The CSS module

const UserProfile = ({ name, phone, balance, bonus }) => {
  const imageUri = `https://robohash.org/${name}?set=set3`;
  
  return (
    <div className={styles.profileContainer}>
      <div className={styles.leftSection}>
        <img src={imageUri} alt={`${name}'s avatar`} className={styles.avatar} />
        <div className={styles.namePhoneContainer}>
          <h2 className={styles.name}>{name}</h2>
          <p className={styles.phoneNumber}>{phone}</p>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.balanceBonusContainer}>
        <div className={styles.balance}>
          <span className={styles.amount}>{balance} gdes</span>
          <span className={styles.label}>Balance</span>
        </div>
        <div className={styles.bonus}>
          <span className={styles.amount}>{bonus} gdes</span>
          <span className={styles.label}>Bonus</span>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
