import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Image } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import { navigate } from "../../redux/actions/navigation";
import { login } from "../../redux/actions/user";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import Input from "../../components/Input/Input";

class LoginScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      password: "",
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSignIn = async () => {
    const { phone, password } = this.state;
    await this.props.dispatch(login(phone, password));
  };

  handleSignUp = (e) => {
    e.preventDefault();
    this.props.dispatch(navigate("/sign-up"));
  };

  handleForgotPassword = (e) => {
    e.preventDefault();
    this.props.dispatch(navigate("/forgot-password"));
  };

  render() {
    const { phone, password } = this.state;
    const { navigation } = this.props;

    if (navigation.navigate && window.location.pathname !== navigation.route) {
      return <Redirect push to={navigation.route} />;
    }

    const styles = {
      globalContainer: {
        backgroundImage: 'url("/background.png")',
        backgroundSize: 'cover',
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        color: 'white',
      },
      container: {
        backgroundImage: 'url("/background.png")',
        backgroundSize: 'cover',
        width: '100%',
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        color: 'white',
        padding: '1em',
      },
      logoContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        margin: '3em 0.5em 0.5em 0.5em',
        color: 'white',
      },
      inputsContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 3,
        justifyContent: 'center',
        margin: '0.5em',
        padding: '0.5em',
        color: '#ffffff',
      },
      buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: '0.5em',
        color: 'white',
      },
      actionsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0.1em',
      },
      logo: {
        width: '50%',
        objectFit: 'scale-down',
      },
      textRight: {
        marginLeft: 'auto',
      },
      button: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        backgroundColor: '#ce1d1a',
        color: '#ffffff',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '0.9',
      },
      handleSignUpButton: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        backgroundColor: 'transparent',
        color: '#ffffff',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '0.9',
        border: '2px solid red',
      },
      textCenter: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '0.1em',
        fontSize: '1.1em',
        color: 'white',
      },
      mediaQuery: {
        container: {
          textAlign: 'center',
          padding: '1em',
        },
        logoContainer: {
          margin: '1em 0.5em 0.5em 0.5em',
        },
        inputsContainer: {
          width: '100%',
          margin: '0.2em',
          padding: '0.2em',
        },
        buttonsContainer: {
          width: '100%',
          margin: '0.2em',
          padding: '0.2em',
        },
        button: {
          width: '100%',
        },
        handleSignUpButton: {
          width: '100%',
        },
        textCenter: {
          fontSize: '1em',
        },
      },
    };

    return (
      <div style={styles.globalContainer}>
        <div style={styles.container}>
          <div style={styles.logoContainer}>
            <Image src="/logo3_1.png" style={styles.logo} />
          </div>
          <div style={styles.inputsContainer}>
            <Input
              value={phone}
              name="phone"
              placeholder={getLabel("Phone")}
              onChange={this.handleInputChange}
              type="Number"
            />
            <PasswordInput
              value={password}
              name="password"
              placeholder={getLabel("Password")}
              onChange={this.handleInputChange}
            />
            <div style={styles.actionsContainer}>
              <div
                style={styles.textRight}
                onClick={this.handleForgotPassword}
              >
                {getLabel("ForgotYourPassword")}
              </div>
            </div>
          </div>
          <div style={styles.buttonsContainer}>
            <Button style={styles.button} onClick={this.handleSignIn}>
              {getLabel("SignIn")}
            </Button>
          </div>
          <div style={styles.textCenter}>{getLabel("OR")}</div>
          <div style={styles.buttonsContainer}>
            <Button
              style={styles.handleSignUpButton}
              onClick={this.handleSignUp}
            >
              {getLabel("SignUp")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ navigation }) => ({ navigation });

export default connect(mapStateToProps)(LoginScreen);
