import React, { PureComponent } from "react";
import { connect } from "react-redux";
import styles from "./GameScreen.module.css";
import { endNavigate } from "../../redux/actions/navigation";
import IconGrid from "../../components/IconGrid/IconGrid";
import Menu from "../../components/Menu/Menu";
import { getLabel } from "../../constants/Strings";
import { Button, Modal } from "semantic-ui-react";
import { updateCredit, userCanWinNow } from "../../redux/actions/user";
import Congratulation from "../../components/Congratulation/Congratulation";

const DEFAULT_PRICE = 5;
const DEFAULT_LOGO_PRICE = 100000;

class GameScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      iconsData: this.shuffleArray(this.getInitialIconsData()),
      showLowBalanceModal: false,
      shuffledNumbers: this.generateShuffledNumbers(),
      revealAllIcons: false,
      showCongratulation: false,
      isSelectionMade: false,
      showModalConditionPlay: false,
      amountWin: 0,
      canWinNow: false,
    };

    if (this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
  }

  getInitialIconsData = () => {
    this.props.dispatch(userCanWinNow());
    return [
      { src: "/sadImage.png" },
      { src: "/logo3_1.png", isWinner: true, bonus: false },
      { src: "/sadImage.png" },
      {
        src: "/emptyLogo.png",
        label: 50,
        labelPosition: "center",
        isWinner: true,
        bonus: true,
      },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },

      {
        src: "/emptyLogo.png",
        label: 50,
        labelPosition: "center",
        isWinner: true,
        bonus: true,
      },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      {
        src: "/emptyLogo.png",
        label: 50,
        labelPosition: "center",
        isWinner: true,
        bonus: true,
      },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      {
        src: "/emptyLogo.png",
        label: 100,
        labelPosition: "center",
        isWinner: true,
        bonus: true,
      },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },

      { src: "/sadImage.png" },
      {
        src: "/emptyLogo.png",
        label: 100,
        labelPosition: "center",
        isWinner: true,
        bonus: true,
      },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },

      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      {
        src: "/emptyLogo.png",
        label: 2500,
        labelPosition: "center",
        isWinner: true,
        bonus: true,
      },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      {
        src: "/emptyLogo.png",
        label: 50,
        labelPosition: "center",
        isWinner: true,
        bonus: true,
      },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      {
        src: "/emptyLogo.png",
        label: 1000,
        labelPosition: "center",
        isWinner: true,
        bonus: true,
      },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      {
        src: "/emptyLogo.png",
        label: 1000,
        labelPosition: "center",
        isWinner: true,
        bonus: true,
      },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
      {
        src: "/emptyLogo.png",
        label: 2500,
        labelPosition: "center",
        isWinner: true,
        bonus: true,
      },
      { src: "/sadImage.png" },
      { src: "/sadImage.png" },
    ];
  };

  shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  generateShuffledNumbers = () => {
    const numbers = Array.from({ length: 99 }, (_, i) =>
      String(i + 1).padStart(2, "0")
    );
    numbers.sort(() => Math.random() - 0.5);
    return numbers;
  };

  checkFundsAndNavigate = () => {
    const { profile } = this.props.user;

    if (profile) {
      let remainingCost = DEFAULT_PRICE;

      if (profile.bonus > 0) {
        const deductionFromBonus = Math.min(profile.bonus, remainingCost);
        remainingCost -= deductionFromBonus;

        this.props.dispatch(
          updateCredit(profile.bonus - deductionFromBonus, "Bonus", "Reduce")
        );
      }

      if (remainingCost > 0 && profile.balance >= remainingCost) {
        this.props.dispatch(
          updateCredit(profile.balance - remainingCost, "Balance", "Reduce")
        );
      } else if (remainingCost > 0) {
        this.props.history.push("/operation");
        return;
      }
    }
  };

  changeIconPosition = (icon, index, iconsArray) => {
    let targetIndex;
    if (icon.isWinner) {
      for (let i = 0; i < iconsArray.length; i++) {
        if (iconsArray[i].src === "/sadImage.png") {
          targetIndex = i;
          break;
        }
      }

      if (targetIndex !== undefined) {
        // Swap the selected icon with the target position
        const temp = iconsArray[targetIndex];
        iconsArray[targetIndex] = iconsArray[index];
        iconsArray[index] = temp;
      }
    }
    this.setState({
      iconsData: iconsArray,
      revealAllIcons: true,
      isSelectionMade: true,
    });
  };

  handleSelection = (icon, index, iconsArray) => {
    const updateAmount =
      icon.isWinner === true
        ? icon.bonus
          ? icon.label
          : DEFAULT_LOGO_PRICE
        : 0;

    if (
      !this.props.user?.canWin?.allowedWin ||
      updateAmount > this.props.user?.canWin?.amount
    ) {
      let targetIndex;
      if (icon.isWinner) {
        for (let i = 0; i < iconsArray.length; i++) {
          if (iconsArray[i].src === "/sadImage.png") {
            targetIndex = i;
            break;
          }
        }

        if (targetIndex !== undefined) {
          // Swap the selected icon with the target position
          const temp = iconsArray[targetIndex];
          iconsArray[targetIndex] = iconsArray[index];
          iconsArray[index] = temp;
        }
      }
      this.setState({
        iconsData: iconsArray,
        revealAllIcons: true,
        isSelectionMade: true,
      });
      return;
    }
    if (icon.isWinner) {
      this.props.dispatch(
        updateCredit(updateAmount, icon.bonus ? "Bonus" : "Balance", "add")
      );
      this.setState({ showCongratulation: true, amountWin: updateAmount });
    }

    this.setState({ revealAllIcons: true, isSelectionMade: true });
  };

  handleCancel = () => {
    this.props.history.push("/home-screen");
  };

  handleAcceptModal = () => {
    this.setState({ showModalConditionPlay: false });
  };

  handleDeclineModal = () => {
    this.props.history.push("/home-screen");
  };

  handleReplay = (e) => {
    const { profile } = this.props.user;
    this.setState({ showModalConditionPlay: true });
    if (
      (profile && profile.balance >= DEFAULT_PRICE) ||
      profile.bonus >= DEFAULT_PRICE
    ) {
      this.setState({
        showCongratulation: false,
        iconsData: this.shuffleArray(this.getInitialIconsData()),
        revealAllIcons: false,
        isSelectionMade: false,
        shuffledNumbers: this.generateShuffledNumbers(),
      });
      e.preventDefault();
      if (profile.bonus >= DEFAULT_PRICE) {
        this.props.dispatch(updateCredit(DEFAULT_PRICE, "Bonus", "Reduce"));
      } else if (profile.balance >= DEFAULT_PRICE) {
        this.props.dispatch(updateCredit(DEFAULT_PRICE, "Balance", "Reduce"));
      } else {
        this.setState({ showLowBalanceModal: true });
      }
    } else {
      this.setState({ showLowBalanceModal: true });
    }
  };
  handleCloseModal = () => {
    this.setState({ showLowBalanceModal: false });
  };

  render() {
    const { profile } = this.props.user;

    const {
      iconsData,
      revealAllIcons,
      showCongratulation,
      isSelectionMade,
      shuffledNumbers,
    } = this.state;

    if (profile?.bonus > 1000) {
      const amountAddedBalance = profile.bonus - 1000;
      this.props.dispatch(updateCredit(amountAddedBalance, "Bonus", "Reduce"));
      this.props.dispatch(updateCredit(amountAddedBalance, "Balance", "add"));
    }

    return (
      <div className={styles.container}>
        <Modal
          open={this.state.showLowBalanceModal}
          onClose={this.handleCloseModal}
        >
          <Modal.Header style={{ backgroundColor: "red", color: "white" }}>
            {getLabel("LowBalance")}
          </Modal.Header>
          <Modal.Content style={{ backgroundColor: "red", color: "white" }}>
            {getLabel("LowBalanceText")}
          </Modal.Content>
          <Modal.Actions style={{ backgroundColor: "red", color: "white" }}>
            <Button onClick={this.handleCloseModal}>{getLabel("Close")}</Button>
          </Modal.Actions>
        </Modal>
        <Menu />

        {showCongratulation && <Congratulation bonus={this.state.amountWin} />}
        <div className={styles.centerContent}>
          <IconGrid
            iconsArray={iconsData}
            onSelection={this.handleSelection}
            revealAllIcons={revealAllIcons}
            shuffledNumbers={shuffledNumbers}
          />
        </div>
        <Modal
          open={this.state.showModalConditionPlay}
          onClose={this.handleDeclineModal} // Modal will close on decline
        >
          <Modal.Content style={{ backgroundColor: "red", color: "white" }}>
            {getLabel("RulesFeeText")} {DEFAULT_PRICE} Gdes
          </Modal.Content>
          <Modal.Actions style={{ backgroundColor: "red", color: "white" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "5%",
              }}
            >
              <Button
                onClick={this.handleDeclineModal}
                style={{ marginRight: "10px" }}
              >
                {getLabel("Decline")}
              </Button>
              <Button onClick={this.handleAcceptModal}>
                {getLabel("Accept")}
              </Button>
            </div>
          </Modal.Actions>
        </Modal>

        <div className={styles.positionPlayButton}>
          <div className={styles.playButton}>
            <Button
              style={{ backgroundColor: "#F99500", color: "white" }}
              onClick={this.handleReplay}
              disabled={!isSelectionMade}
            >
              {getLabel("Replay")}
            </Button>
          </div>

          <div className={styles.cancelButton}>
            <Button
              style={{ backgroundColor: "#F99500", color: "white" }}
              onClick={this.handleCancel}
              disabled={!isSelectionMade}
            >
              {getLabel("Cancel")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps({ user, navigation }) {
  return { user, navigation };
}

export default connect(mapStateToProps)(GameScreen);
