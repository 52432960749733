
import React from 'react';
import styles from './Congratulation.module.css'; // Create and import the CSS module for styling
import { getLabel } from "../../constants/Strings";
const Congratulation = ({bonus}) => {
  return (
    <div className={styles.congratulationsContainer}>
      <h1 className={styles.congratulationsText}> {getLabel("WinningHeaderText")}</h1>
      <p className={styles.congratulationsMessage}>{getLabel("WinningBodyText")} {bonus} gdes</p>
    </div>
  );
};

export default Congratulation;
