import axios from "axios";
import config from "../config";

// axios default configs
axios.defaults.mode = "no-cors";
axios.defaults.withCredentials= true;
const _Api = axios.create();
_Api.defaults.baseURL = config.api_base_url;

export default _Api;
